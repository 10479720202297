import Application from 'modules/application';
import SEO from 'modules/seo';
import React from 'react';

const NotFoundPage = () => (
  <Application>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Application>
)

export default NotFoundPage
